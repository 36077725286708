* {
  font-family: "Raleway", sans-serif;
  box-sizing: border-box;
}
.topBar {
  background-color: #e5e2d8;
  color: #3a2c1d;
  height: 100px;
  font-weight: bold;
  font-size: 40px;
  text-transform: uppercase;
  width: 100%;
  display: flex;
  margin-bottom: 50px;
  align-content: center;
  flex-direction: row;
}
.logo {
  align-items: center;
  width: 150px;
}
.topLeft {
  display: flex;
  align-items: center;
  width: 25%;
  text-align: left;
  padding-left: 30px;
}
.topCenter {
  width: 50%;
  text-align: center;
}
.topRight {
  display: inline-flex;
  align-items: center;
  flex-direction: row-reverse;
  width: 25%;
  padding-right: 30px;
}
.card_box {
  padding: 30px;
  background-color: #e5e2d8;
  margin: 10px;
  width: 30%;
}
.card_inner {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-transform: uppercase;
}
h2 {
  text-align: center;
  font-size: 30px;
}
.card_space {
  background-color: #3a2c1d;
  color: #fff;
  padding: 13px 20px;
  font-weight: bold;
  font-size: 20px;
}
.card_time {
  padding: 10px;
  font-size: 25px;
  color: #3a2c1d;
  font-weight: bold;
}

.cardholder {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  max-height: 90vh;
}

.out_of_office {
  background-color: #f84c4c;
  text-align: center;
  color: white;
  font-weight: bold;
  padding: 10px 50px;
  font-size: 20px;
}
.specialization-title{
  display:block;
  width:30%;
}

.specialization-name{
  text-align: center;
  font-size:1.2rem;
}